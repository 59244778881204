@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  .bg-gradient {
    background-image: linear-gradient(to left, rgba(58, 60, 63, 0.7), rgba(78, 94, 117, 0.7), rgba(107, 114, 128, 0.7));
  }
}
